import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["opportunityEmail", "contactEmail"]

  connect() {
    // Pour déboguer
    console.log("Opportunity form controller connected")
  }

  copyEmail() {
    const email = this.opportunityEmailTarget.value
    console.log("Email copied:", email)
    if (this.hasContactEmailTarget) {
      this.contactEmailTarget.value = email
    }
  }
} 