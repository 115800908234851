import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["startsAt", "endsAt", "allDay", "timeFields"]

  connect() {
    console.log("Appointment form controller connected")
    console.log("Timezone:", Intl.DateTimeFormat().resolvedOptions().timeZone)
  }

  toggleTimeFields() {
    if (this.allDayTarget.checked) {
      // Si "journée entière" est coché, on met les heures à 00:00 et 23:59
      const startDate = new Date(this.startsAtTarget.value)
      const endDate = new Date(this.startsAtTarget.value)
      
      startDate.setHours(0, 0, 0)
      endDate.setHours(23, 59, 59)
      
      this.setDateTime(this.startsAtTarget, startDate)
      this.setDateTime(this.endsAtTarget, endDate)
      
      this.timeFieldsTarget.classList.add('opacity-50', 'pointer-events-none')
    } else {
      this.timeFieldsTarget.classList.remove('opacity-50', 'pointer-events-none')
    }
  }

  updateEndTime() {
    if (this.allDayTarget.checked) return

    const startsAt = new Date(this.startsAtTarget.value)
    if (startsAt) {
      // Ajoute une heure à la date de début
      const endsAt = new Date(startsAt.getTime() + (60 * 60 * 1000))
      this.setDateTime(this.endsAtTarget, endsAt)
    }
  }

  setDateTime(input, date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    
    input.value = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  }
} 